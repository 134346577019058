import { math } from 'polished';
import breakpoints from './breakpoints';

export const smallScreenCss = style => `
  @media (max-width: ${breakpoints.md}) {
    ${style}
  }
`;

export const bigScreenCss = style => `
  @media (min-width: ${math(`${breakpoints.md} + 1px`)}) {
    ${style}
  }
`;

export const maxScreenCss = (breakpoint, style) => `
  @media (max-width: ${breakpoint}) {
    ${style}
  }
`;

export const minScreenCss = (breakpoint, style) => `
  @media (min-width: ${breakpoint}) {
    ${style}
  }
`;
