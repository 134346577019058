import styled from 'styled-components';
import { smallScreenCss } from '../../styles/responsive-css';

const BigScreen = styled.div`
  ${smallScreenCss(`
    display: none;
  `)}
`;

export default BigScreen;
