import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { Link } from 'gatsby';
import { Badge, Breadcrumb, ConfigProvider } from 'antd';
import vnLocale from 'antd/lib/locale/vi_VN';
import { AuthProvider } from '../../utils/useAuth';
import GlobalStyle from '../../styles/GlobalStyle';
import breakpoints from '../../styles/breakpoints';
import NavigationMenu from './NavigationMenu';
import 'antd/dist/antd.less';
import 'nprogress/nprogress.css';
import 'moment/locale/vi';

import { bigScreenCss, maxScreenCss } from '../../styles/responsive-css';
import colors from '../../styles/colors';
import Footer from './Footer';
import BigScreen from '../Responsive/BigScreen';

const DefaultMainContent = styled.main`
  margin: 0 auto;
  max-width: ${breakpoints.xl};
  margin-top: 56px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  background: ${colors.white};
  padding: 24px;
  box-shadow: 4px 4px 20px 5px #efefef;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const BreadCrumbContainer = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${bigScreenCss(`
    padding: 16px 0;
  `)}

  ${maxScreenCss(breakpoints.xl, `
    padding-left: 12px;
    padding-right: 12px;
  `)}
`;

const Layout = ({ mainContent: MainContent, breakCrumbs, children, toolbar, onlyContent }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <ConfigProvider locale={vnLocale}>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#047bd3" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#001529" />
        <meta name="msapplication-TileColor" content="#001529" />
        <meta name="theme-color" content="#001529" />
        <script
          data-ad-client={process.env.GATSBY_GOOGLE_ADSENSE_CLIENT_ID}
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        />
      </Helmet>
      <AuthProvider>
        <GlobalStyle bodyBgColor="#f0f2f5" />
        {!onlyContent && (
          <>
            <MainContent>
              {breakCrumbs && (
                <BreadCrumbContainer>
                  <Breadcrumb>
                    <Link to={"/"}>
                      <Breadcrumb.Item>Trang chủ</Breadcrumb.Item>
                    </Link>
                    {_.map(breakCrumbs, breakCrumb => (
                      breakCrumb.to ? (
                        <Link to={breakCrumb.to} key={breakCrumb.to}>
                          <Breadcrumb.Item>
                            {breakCrumb.label}
                            {breakCrumb.labelVersion && (
                              <>&nbsp;<Badge size="small" count={`v${breakCrumb.labelVersion}`} style={{ backgroundColor: '#c5c5c5', fontSize: 8 }} /></>
                            )}
                          </Breadcrumb.Item>
                        </Link>
                      ) : (
                        <Breadcrumb.Item key={breakCrumb.label}>
                          {breakCrumb.label}
                          {breakCrumb.labelVersion && (
                            <>&nbsp;<Badge size="small" count={`v${breakCrumb.labelVersion}`} style={{ backgroundColor: '#c5c5c5', fontSize: 8 }} /></>
                          )}
                        </Breadcrumb.Item>
                      )
                    ))}
                  </Breadcrumb>
                  {toolbar}
                </BreadCrumbContainer>
              )}
              {!breakCrumbs && (
                <BigScreen><div style={{height: 32}} /></BigScreen>
              )}
              <ContentWrapper>{children}</ContentWrapper>
            </MainContent>
            <NavigationMenu />
            <Footer />
          </>
        )}
        {onlyContent && (
          <>{children}</>
        )}
      </AuthProvider>
    </ConfigProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainContent: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  breakCrumbs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
    labelVersion: PropTypes.string,
  })),
  toolbar: PropTypes.node,
  onlyContent: PropTypes.bool,
};

Layout.defaultProps = {
  mainContent: DefaultMainContent,
  toolbar: null,
  onlyContent: false,
};

export default Layout;
