import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import AppsRegistry from '@techartvn/tinh1s-apps-registry';

const iconPool = {};
_.forEach(AppsRegistry.appGroups, appGroup => {
  _.forEach(appGroup.apps, app => {
    iconPool[app.slug] = app.Icon;
  });
});

function AppIcon({ slug }) {
  const Icon = iconPool[slug];
  if (!Icon) {
    return <></>;
  }
  return <Icon />;
}

AppIcon.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default AppIcon;
