import React from 'react';
import styled from 'styled-components';
import { FaFacebookF } from 'react-icons/fa';
import { Link } from 'gatsby';
import { Badge } from 'antd';
import { version } from '../../../package.json'

import breakpoints from '../../styles/breakpoints';
import { maxScreenCss, smallScreenCss } from '../../styles/responsive-css';
import Delimiter from '../Delimiter';
import DonateButton from '../DonateButton';

const Wrapper = styled.footer`
  margin-top: 45px;
  border-top: solid 1px #e0e0e0;

  ${smallScreenCss(`
    margin-top: 0;
  `)}

  ${maxScreenCss(
    breakpoints.xl,
    `
    margin-top: 0;
  `
  )}
`;

const MainContainer = styled.div`
  margin: 0 auto;
  padding: 2rem 1rem 70px 1rem;
  max-width: ${breakpoints.xl};
  color: #7e7e7e;
`;

const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const MenuItem = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  font-weight: 600;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: #7e7e7e;
    text-decoration: none;

    &:hover {
      color: #2e2e2e;
    }
  }
`;

const Top = styled.div`
  display: flex;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const TopTitle = styled.h3`
  font-size: 1.125rem;
  font-style: bold;
  margin-bottom: 1rem;
`;

const AboutUs = styled.div`
  width: 28rem;
  margin-right: 1rem;

  p {
    margin: 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: auto;
    margin: 0 0 1.5rem 0;
  }
`;

const Contact = styled.div`
  width: 24rem;

  @media (max-width: ${breakpoints.sm}) {
    width: auto;
  }
`;

const Bottom = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Copyright = styled.span`
  flex: 1;
  color: #7e7e7e;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 10px;
  }
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

const SocialButton = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  color: #637076;
  background: #eceff0;
  border-radius: 50%;

  & + & {
    margin-left: 10px;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <MainContainer>
        <Top>
          <AboutUs>
            <TopTitle>tinh1s.com <Badge size="small" count={`v${version}`} style={{ backgroundColor: '#52c41a' }} /></TopTitle>
            <p>
              Hỗ trợ tính toán nhiều thứ trong cuộc sống của bạn: BHXH một lần, lãi suất ngân hàng,
              đo nồng độ cồn, v.v...
            </p>
          </AboutUs>
          <Contact>
            <TopTitle>Liên hệ</TopTitle>
            <div>
              <span>Email: </span>
              <a href="mailto:hop.ho@techartvn.com">hop.ho@techartvn.com</a>
            </div>
          </Contact>
        </Top>
        <Menu>
          <MenuItem>
            <Link to="/gioi-thieu">Giới thiệu</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/lien-he">Trợ giúp</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/dieu-khoan">Điều khoản</Link>
          </MenuItem>
        </Menu>
        <Delimiter />
        <Bottom>
          <Copyright>
            <span>Bản quyền thuộc về</span>&nbsp;
            <a target="_blank" href="https://techartvn.com">TechArtVN</a>&nbsp;
            <span>{`© ${new Date().getFullYear()}`}</span>
          </Copyright>
          <SocialButtons>
            <DonateButton />
            <SocialButton href="https://www.facebook.com/tinh1s">
              <FaFacebookF />
            </SocialButton>
          </SocialButtons>
        </Bottom>
      </MainContainer>
    </Wrapper>
  );
}

export default Footer;
