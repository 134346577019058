import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { lighten } from 'polished';
import { RiPagesLine } from 'react-icons/all';
import colors from '../../styles/colors';

const StyledLink = styled(Link)`
  height: 100%;
  color: ${colors.white};

  &.is-active {
    color: ${colors.blue[50]};
    background-color: ${lighten(0.07, '#001529')};
  }
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

const Title = styled.div`
  margin-top: 2px;
  font-size: 0.625rem;
`;

const NavItem = ({ icon, link, title, className }) => (
  <StyledLink to={link} className={className} activeClassName="is-active">
    <Wrapper>
      {icon || <RiPagesLine />}
      <Title>{title}</Title>
    </Wrapper>
  </StyledLink>
);

NavItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  className: '',
  icon: null,
};

export default NavItem;
