import { Button } from 'antd';
import React from 'react';
import { Link, StaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { lighten } from 'polished';
import { MdMenu } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/all';
import * as _ from 'lodash';

import PropTypes from 'prop-types';
import useAuth from '../../utils/useAuth';
import AppIcon from '../AppIcon';
import Dropdown from '../Dropdown';
import BigScreen from '../Responsive/BigScreen';
import SmallScreen from '../Responsive/SmallScreen';
import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';
import NavItem from './NavItem';
import logoImgSrc from '../../images/tinh1s-logo-6.png';
import { isBrowser } from '../../utils/browser';
import { smallScreenCss } from '../../styles/responsive-css';

const NavigationLink = styled(Link).attrs(props => ({ to: props.to || '/' }))`
  display: inline-block;
  text-decoration: none;
  color: ${colors.white};
  font-weight: normal;
  padding: 5px 15px;
  border-radius: 8px;

  &.is-active {
    color: ${colors.blue[50]};
    background-color: ${lighten(0.07, '#001529')};
  }
`;

const TopMenuWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.grey[60]};
  background-color: #001529;
  z-index: 2;
`;

const TopMenuContainer = styled.div`
  max-width: ${breakpoints.xl};
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 1;

  ${NavigationLink} + ${NavigationLink} {
    margin-left: 8px;
  }

  ${smallScreenCss(`
    justify-content: center;
  `)}
`;

const BottomMenuWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  border-top: 1px solid ${colors.grey[60]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001529;
  z-index: 2;

  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
  }
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Header = styled.span`
  color: ${colors.white};
  font-size: 1.5rem;
`;

const SignupLink = styled(Button).attrs(() => ({
  onClick: () => navigate('/dang-ky'),
  type: 'primary',
}))``;

const LoginLink = styled(Button).attrs(() => ({ onClick: () => navigate('/dang-nhap') }))`
  margin-right: 10px;
`;

const MenuIcon = styled(MdMenu)`
  font-size: 1.5rem;
  margin-top: 8px;
  cursor: pointer;
  color: ${colors.blue[50]};
  background-color: ${lighten(0.07, '#001529')};
  padding: 10px;
  height: 40px;
  width: 40px;
  border-radius: 8px;
`;

const AuthMenu = styled.div`
  padding: 0.8rem;

  ${LoginLink},
  ${SignupLink} {
    width: 100%;
    margin: 0.3rem 0;
    text-align: center;
  }
`;

const Logo = styled.img.attrs({ src: logoImgSrc })`
  height: 35px;
  width: 65px;
  object-fit: cover;
  margin: 0 15px 0 0;

  ${smallScreenCss(`
    position: absolute;
    left: 24px;
  `)}
`;

// const AppIcon = loadable(() => import('../AppIcon'));

function NavigationMenu({ topApps }) {
  const { user } = useAuth();

  const authInfo = user ? (
    <></>
  ) : (
    <Dropdown trigger={<MenuIcon />} position="right" triggerHeight="40px">
      <AuthMenu>
        <LoginLink>Đăng nhập</LoginLink>
        <SignupLink>Đăng ký</SignupLink>
      </AuthMenu>
    </Dropdown>
  );

  return (
    <>
      <TopMenuWrapper>
        <TopMenuContainer>
          <Logo />
          <BigScreen>
            <NavigationLink activeClassName="is-active">Trang chủ</NavigationLink>
            {_.map(topApps, app => (
              <NavigationLink
                key={app.fullSlug}
                activeClassName="is-active"
                to={`/${process.env.GATSBY_APP_SLUG}/${app.fullSlug}`}
              >
                {app.name}
              </NavigationLink>
            ))}
          </BigScreen>
          <SmallScreen>
            <Header>Tính 1s</Header>
          </SmallScreen>
          {false && <RightPanel>{isBrowser() && authInfo}</RightPanel>}
        </TopMenuContainer>
      </TopMenuWrapper>
      <SmallScreen>
        <BottomMenuWrapper>
          <NavItem className="nav-item" title="Trang chủ" link="/" icon={<AiOutlineHome />} />
          {_.map(topApps, app => (
            <NavItem
              key={app.fullSlug}
              className="nav-item"
              title={app.name}
              link={`/${process.env.GATSBY_APP_SLUG}/${app.fullSlug}`}
              icon={<AppIcon slug={app.slug} />}
            />
          ))}
        </BottomMenuWrapper>
      </SmallScreen>
    </>
  );
}

NavigationMenu.propTypes = {
  topApps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      fullSlug: PropTypes.string,
    })
  ).isRequired,
};

export default function NavigationMenuWrapper() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allTopApp {
            nodes {
              name
              slug
              fullSlug
            }
          }
        }
      `}
      render={data => {
        const topApps = data.allTopApp.nodes;
        return <NavigationMenu topApps={topApps} />;
      }}
    />
  );
}
