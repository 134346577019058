import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  height: 35px;
  width: auto;
  margin-bottom: 0;
`;

const DonateButton = () => {
  return (
    <a href="https://www.buymeacoffee.com/techartvn"><Image src="https://img.buymeacoffee.com/button-api/?text=ủng hộ tinh1s.com&emoji=&slug=techartvn&button_colour=5F7FFF&font_colour=ffffff&font_family=Arial&outline_colour=000000&coffee_colour=FFDD00" /></a>
  )
}

export default DonateButton;
