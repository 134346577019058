import styled from 'styled-components';
import { bigScreenCss } from '../../styles/responsive-css';

const SmallScreen = styled.div`
  ${bigScreenCss(`
    display: none;
  `)}
`;

export default SmallScreen;
